/** Tailwind must be the first import **/
@import url("settings/_settings.tailwind");
@import url("settings/_settings.colors");
@import url("settings/_settings.common");
@import url("settings/_settings.fonts");
@import url("common");

@layer base {
  body {
    @apply font-primary;
  }

  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-xl;
  }
}

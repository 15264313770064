:root {
  /** Color primary **/
  --tw-color-primary-100: var(--tw-color-brownie-100);
  --tw-color-primary-200: var(--tw-color-brownie-200);
  --tw-color-primary-300: var(--tw-color-brownie-300);
  --tw-color-primary-400: var(--tw-color-brownie-400);
  --tw-color-primary-500: var(--tw-color-brownie-500);
  --tw-color-primary-600: var(--tw-color-brownie-600);
  --tw-color-primary-700: var(--tw-color-brownie-700);
  --tw-color-primary-800: var(--tw-color-brownie-800);
  --tw-color-primary-900: var(--tw-color-brownie-900);

  /** Color secondary **/
  --tw-color-secondary-100: var(--tw-color-cream-100);
  --tw-color-secondary-200: var(--tw-color-cream-200);
  --tw-color-secondary-300: var(--tw-color-cream-300);
  --tw-color-secondary-400: var(--tw-color-cream-400);
  --tw-color-secondary-500: var(--tw-color-cream-500);
  --tw-color-secondary-600: var(--tw-color-cream-600);
  --tw-color-secondary-700: var(--tw-color-cream-700);
  --tw-color-secondary-800: var(--tw-color-cream-800);
  --tw-color-secondary-900: var(--tw-color-cream-900);

  /** Color tertiary **/
  --tw-color-tertiary-50: var(--tw-color-orange-50);
  --tw-color-tertiary-70: var(--tw-color-orange-70);
  --tw-color-tertiary-100: var(--tw-color-orange-100);
  --tw-color-tertiary-200: var(--tw-color-orange-200);
  --tw-color-tertiary-300: var(--tw-color-orange-300);
  --tw-color-tertiary-400: var(--tw-color-orange-400);
  --tw-color-tertiary-500: var(--tw-color-orange-500);
  --tw-color-tertiary-600: var(--tw-color-orange-600);
  --tw-color-tertiary-700: var(--tw-color-orange-700);
  --tw-color-tertiary-800: var(--tw-color-orange-800);
  --tw-color-tertiary-900: var(--tw-color-orange-900);

  /** Color green **/
  --tw-color-green-100: #e6eeee;
  --tw-color-green-200: #acc0c0;
  --tw-color-green-300: #739d9c;
  --tw-color-green-400: #3a7a78;
  --tw-color-green-500: #025754;
  --tw-color-green-600: #024643;
  --tw-color-green-700: #013432;
  --tw-color-green-800: #002322;
  --tw-color-green-900: #011;

  /** Color orange **/
  --tw-color-orange-50: #fff7f3;
  --tw-color-orange-70: #f9f0eb;
  --tw-color-orange-100: #fbebe0;
  --tw-color-orange-200: #f6d7c2;
  --tw-color-orange-300: #edaf84;
  --tw-color-orange-400: #e99b66;
  --tw-color-orange-500: #e58747;
  --tw-color-orange-600: #d2671e;
  --tw-color-orange-700: #954915;
  --tw-color-orange-800: #69330f;
  --tw-color-orange-900: #351a07;

  /** Color cream **/
  --tw-color-cream-100: #fdfaf3;
  --tw-color-cream-200: #fdf7ed;
  --tw-color-cream-300: #faefda;
  --tw-color-cream-400: #f8e9ce;
  --tw-color-cream-500: #f5deb5;
  --tw-color-cream-600: #ebbd6a;
  --tw-color-cream-700: #e19b1f;
  --tw-color-cream-800: #966714;
  --tw-color-cream-900: #4b340a;

  /** Color mint **/
  --tw-color-mint-100: #f4f8f1;
  --tw-color-mint-200: #e6f1e2;
  --tw-color-mint-300: #dbead4;
  --tw-color-mint-400: #cee2c6;
  --tw-color-mint-500: #b5d4a9;
  --tw-color-mint-600: #89bb76;
  --tw-color-mint-700: #61984c;
  --tw-color-mint-800: #416633;
  --tw-color-mint-900: #203319;

  /** Color sky **/
  --tw-color-sky-100: #eef8fa;
  --tw-color-sky-200: #def1f5;
  --tw-color-sky-300: #cdeaef;
  --tw-color-sky-400: #bce3ea;
  --tw-color-sky-500: #9bd5df;
  --tw-color-sky-600: #62bdcd;
  --tw-color-sky-700: #379bac;
  --tw-color-sky-800: #256773;
  --tw-color-sky-900: #123439;

  /** Color brownie **/
  --tw-color-brownie-100: #d5cac6;
  --tw-color-brownie-200: #c0b0aa;
  --tw-color-brownie-300: #a38c83;
  --tw-color-brownie-400: #9b7b6f;
  --tw-color-brownie-500: #765346;
  --tw-color-brownie-600: #6b4c40;
  --tw-color-brownie-700: #543b32;
  --tw-color-brownie-800: #412e27;
  --tw-color-brownie-900: #32231d;

  /** Color basics **/
  --tw-color-white: #fff;
  --tw-color-black: #152127;

  /** Color status **/
  --tw-color-status-warning: #e58747;
  --tw-color-status-error: #eb5757;
  --tw-color-status-success: #89bb76;

  /** Color text **/
  --tw-color-text-primary: var(--tw-color-brownie-500);
  --tw-color-text-secondary: var(--tw-color-brownie-400);
  --tw-color-text-tertiary: var(--tw-color-black);
  --tw-color-text-alternative: var(--tw-color-white);

  /** Color state primary **/
  --tw-color-state-primary-default: var(--tw-color-brownie-500);
  --tw-color-state-primary-hover: var(--tw-color-brownie-700);
  --tw-color-state-primary-disabled: var(--tw-color-brownie-200);

  /** Color state secondary **/
  --tw-color-state-secondary-default: var(--tw-color-cream-700);
  --tw-color-state-secondary-hover: var(--tw-color-cream-800);
  --tw-color-state-secondary-disabled: var(--tw-color-cream-400);

  /** Backgrounds Neutrals **/
  --tw-color-surface-00: var(--tw-color-white);
  --tw-color-surface-01: var(--tw-color-orange-50);
  --tw-color-surface-02: var(--tw-color-orange-70);
  --tw-color-surface-03: var(--tw-color-orange-50);
  --tw-color-surface-04: var(--tw-color-orange-50);

  /** Backgrounds Surfaces **/
  --tw-color-surface-05: var(--tw-color-orange-50);
  --tw-color-surface-06: var(--tw-color-orange-50);
  --tw-color-surface-07: var(--tw-color-orange-50);
  --tw-color-surface-08: var(--tw-color-orange-50);
  --tw-color-surface-09: var(--tw-color-brownie-400);
  --tw-color-surface-10: var(--tw-color-brownie-500);
  --tw-color-surface-11: var(--tw-color-orange-50);
  --tw-color-surface-12: var(--tw-color-brownie-700);
}

.special-label {
  @apply hidden;
}

.scrollbar-primary::-webkit-scrollbar {
  @apply h-1  w-0;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
  @apply h-1 rounded-lg border bg-primary-400;
}

.scrollbar-primary::-webkit-scrollbar-thumb:hover {
  @apply bg-primary-600;
}

.scrollbar-transparente::-webkit-scrollbar {
  @apply h-1 w-0;
}

.scrollbar-transparente::-webkit-scrollbar-thumb {
  @apply h-1 rounded-lg border bg-transparent;
}

.scrollbar-secondary::-webkit-scrollbar {
  @apply h-1 w-[0.001rem];
}

.scrollbar-secondary::-webkit-scrollbar-thumb {
  @apply rounded-lg border bg-secondary-400;
}

.scrollbar-secondary::-webkit-scrollbar-thumb:hover {
  @apply bg-secondary-600;
}

.swiper-pagination-bullet {
  @apply !h-3 !w-3 border border-[#eee] !bg-white;
}

.swiper-pagination-bullet-active {
  @apply bg-white;
}

*[class*="swiper-button-disabled"] {
  @apply !cursor-default !opacity-50;
}

.swiper-button-disabled {
  @apply !opacity-50;
}

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @apply max-w-none;
}

.is-current {
  border-bottom: 1px solid;
}

.ot-host-td {
  width: 50% !important;
}

.ot-cookies-td {
  width: 45% !important;
}

.ot-cookies-type {
  width: 30% !important;
}

.pop-up {
  & > div {
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      iframe {
        max-width: 95%;
      }
    }
  }
}

.grecaptcha-badge {
  display: none !important;
}

.AlertaFondo {
  background-color: blue;
}

.BotonConfirmacion {
  /* identical to box height, or 125% */
  display: flex;
  width: 205px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border: 1px solid #9b7b6f;
  border-radius: 100px;
  background: #9b7b6f;

  /* Basic/white */
  color: #fff;
  cursor: pointer;
  font-family: "Basis Grotesque Pro", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.006em;
  line-height: 20px;
  text-align: center;

  &:hover {
    background: #9b7b6f;
  }

  &:focus {
    background: #9b7b6f;
  }
}

.swal2-popup {
  background-color: #fdf7ed !important;
  font-family: "Basis Grotesque Pro", serif;
  font-style: normal !important;
}

@layer base {
  @font-face {
    font-display: optional;
    font-family: "Basis Grotesque";
    font-style: normal;
    font-weight: 300;
    src: local("Basis Grotesque Pro"),
      url("/fonts/BasisGrotesquePro-Light.woff2") format("woff2"),
      url("/fonts/BasisGrotesquePro-Light.woff") format("woff");
  }

  @font-face {
    font-display: optional;
    font-family: "Basis Grotesque";
    font-style: normal;
    font-weight: 400;
    src: local("Basis Grotesque Pro"),
      url("/fonts/BasisGrotesquePro-Medium.woff2") format("woff2"),
      url("/fonts/BasisGrotesquePro-Medium.woff") format("woff");
  }

  @font-face {
    font-display: optional;
    font-family: "Basis Grotesque";
    font-style: normal;
    font-weight: 500;
    src: local("Basis Grotesque Pro"),
      url("/fonts/BasisGrotesquePro-Regular.woff2") format("woff2"),
      url("/fonts/BasisGrotesquePro-Regular.woff") format("woff");
  }

  @font-face {
    font-display: optional;
    font-family: "Basis Grotesque";
    font-style: normal;
    font-weight: bold;
    src: local("Basis Grotesque Pro"),
      url("/fonts/BasisGrotesquePro-Bold.woff2") format("woff2"),
      url("/fonts/BasisGrotesquePro-Bold.woff") format("woff");
  }
}

:root {
  --font-family-primary: "Basis Grotesque", arial;
}
